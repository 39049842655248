import React from 'react'
import {css, SerializedStyles} from '@emotion/react'


const containerStyle = css({
    position: 'relative',
})

const overlapStyle = css({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
})

/**
 * 重ねるためのコンポーネント
 * @param props
 * @constructor
 */
export const OverLap: React.VFC<{
    /* 重ねられる元のコンポーネント */
    children: React.ReactNode,
    /* 上に重なるコンポーネント */
    overlap:React.ReactNode,
    /* 上に重なるコンポーネントのcss */
    overlapCss?:SerializedStyles,
    containerCss?:SerializedStyles
}> = (props) => {
    const { children, overlap, overlapCss, containerCss } = props

    return (
        <div css={[containerStyle, containerCss]}>
            {children}
            <div css={[overlapStyle, overlapCss]}>
                {overlap}
            </div>
        </div>
    )
}
