/**
 * uuidを作成する
 */
export const createUuid = ():string => {
    // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
    // const FORMAT: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    const chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('')

    return chars.map(char => {
        if(char === 'x') return Math.floor(Math.random() * 16).toString(16)
        if(char === 'y') return (Math.floor(Math.random() * 4) + 8).toString(16)
        return char
    }).join('')
}
