
/**
 * サイズの配列を画面幅に応じたpxに変換して出力する
 * @param fontsSize フォントサイズの配列。配列の数によって、サイズ調整される
 * [横幅に依存しないフォントサイズ]
 * [992未満のフォントサイズ, 992以上のフォントサイズ]
 */
export const toSize = (fontsSize: number[]): string => {
    const windowWidth = window.innerWidth
    // console.log('window width', windowWidth)
    if(fontsSize.length === 2) {
        if(windowWidth > 992){
            return `${fontsSize[1]}px`
        }
    }
    return `${fontsSize[0]}px`
}
