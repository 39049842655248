import React, {ChangeEventHandler, useState} from 'react'
import {css} from '@emotion/react'
import { ControllerRenderProps } from 'react-hook-form'
import {inputTagStyle} from '@/styles/global'

const containerStyles = css({
    width: '100%',
    height: '56px',
    background: '#FFFFFF',
    border: '1px solid #EAEBED',
    boxSizing: 'border-box',
    padding: '12px',
    fontSize:'16px',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
        height: '45px',
    },
})

export const NumberField: React.VFC<
    {maxLength?: number, placeholder?: string}  & ControllerRenderProps
> = (props) => {
    const { onChange, maxLength, ...restProps } = props
    const [value, setValue] = useState<string|''>('') // number で扱うと 09→9とされてしまうのでstringにしている

    const onChangeWrapper: ChangeEventHandler<HTMLInputElement> = (event) => {
        const newValue = event.target.value
        if(maxLength && newValue.length > maxLength){
            return
        }
        if (!Number.isNaN(Number(newValue)) || newValue === '') {
            setValue(newValue)
            onChange(event)
        }
    }

    return <input {...restProps} pattern="\d*" inputMode="numeric" css={[inputTagStyle, containerStyles]} onChange={onChangeWrapper} value={value}/>
}
