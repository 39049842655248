import React, {useEffect} from 'react'
import {css} from '@emotion/react'
import {ConfirmBlackTitle} from '@components/atoms/ConfirmBlackTitle'
import Modal from '@components/atoms/Modal'
import {HoverButton} from '@components/atoms/Button/HoverButton'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import {BreakLinePTag} from '@components/atoms/BreakLinePTag'
import {fonts} from '@/styles/fonts'
import {OrderConfirmation, OrderConfirmationResult} from '@/network/api/orderConfirmation'
import {getPrefectureName} from '@/configure/prefectures'
import {dateUtils} from '@/utils/date'
import {CreatedCreditCard} from '@/network/api/stripePublicKey'
import {ErrResult} from '@/network/fetcher'
import {firebaseAuth} from '@/network/firebase/firebaseAuth'
import {usePostCheckout} from '@/network/api/checkout'
import {pTagStyle, ulTagStyle} from '@/styles/global'
import {rootDiv} from '@/utils/htmlElements'
import {HtmlDataset} from '@/configure/htmlDataset'
import {pushDataLayer} from '@/utils/analytics'

const headTtl = css({
    textAlign: 'center',
    fontWeight: '600',
    fontSize: fonts.size.fontMobile18Pc22,
    margin: '0 0 20px',
})

const containerStyle = css({
    maxHeight: '60vh',
    overflow: 'hidden',
    overflowY: 'scroll',
    padding: '0 0 30px',
    width: '100%',
})
const listInfoStyle = css({
    margin: '0 0 40px',
    'li:first-child': {
        borderTop: '1px solid #eee'
    },
    li: {
        display: 'flex',
        borderBottom: '1px solid #eee',
        background: '#fff'
    },
    '@media (max-width: 480px)': {
        margin: '0 0 20px'
    }
})

const contentStyle = css({
    padding: '16px 30px',
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    minWidth: '10px',
    font: '500 13px/1.3em Hiragino Kaku Gothic Pro, sans-serif',
    color: '#3d3d3d',
    wordBreak: 'break-all',
    '@media (max-width: 768px)': {
        padding: '16px 20px'
    },
    '@media (max-width: 480px)': {
        padding: '10px',
        fontSize: '12px',
        display: 'block',
    }
})
const mobileColumStyle = css({
    '@media (max-width: 480px)': {
        padding: '10px',
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column'
    }
})

const titleStyle = css({
    padding: '16px 30px',
    flex: '0 0 200px',
    font: '600 13px/1 Hiragino Kaku Gothic Pro, sans-serif',
    background: '#f4f4f4',
    'box-sizing': 'border-box',
    '@media (max-width: 768px)': {
        padding: '16px 20px',
    },
    '@media (max-width: 480px)': {
        fontSize: '11px',
        padding: '12px',
        flex: '0 0 100px',
    }
})
const productImgStyle = css({
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '80px',
    ':before': {
        content: '\'\'',
        display: 'block',
        paddingTop: '100%'
    },
    '@media (max-width: 480px)': {
        width: '60px',
    }
})
const productTxtStyle = css({
    margin: '0 0 0 10px',
    '@media (max-width: 480px)': {
        margin: '6px 0 0',
    }
})

/*
* モーダルに表示する確認情報
 */
export type OrderConfirmationInfo = {
    email: string,
    password:string,
    creditCard:CreatedCreditCard
    // アカウントがあるユーザのみ存在。なければ新規ユーザ
    userIdToken?:string
} & OrderConfirmation & OrderConfirmationResult

/**
 * ご注文内容の確認画面をカスタマイズする、HTMLから取得したデータセット
 */
export type CustomConfirmationFromHtmlDataset = Omit<HtmlDataset, 'itemCode'>

export const OrderConfirmationModal: React.VFC<{
    close: () => void
    orderConfirmationInfo: OrderConfirmationInfo|undefined
    setPage: React.Dispatch<React.SetStateAction<Page>>
    setErrResult: React.Dispatch<React.SetStateAction<ErrResult|undefined>>
    htmlDataset: CustomConfirmationFromHtmlDataset
}> = (props) => {
    const {close, orderConfirmationInfo, setErrResult, setPage, htmlDataset} = props
    const postCheckout = usePostCheckout()

    useEffect(() => {
        if(!postCheckout.loading && postCheckout.data){
            setPage('thanks')
            // 遷移前のスクロール位置を覚えてしまっているので対策
            window.scrollTo({
                top: rootDiv?.offsetTop || 0,
            })
        }
        if(!postCheckout.loading && postCheckout.error){
            if(postCheckout.error.code && postCheckout.error.message){
                setErrResult(postCheckout.error)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postCheckout.loading])

    useEffect(() => {
        // orderConfirmationInfoがあるときにモーダルがOpenになるので、orderConfirmationInfoがあるときOpenとする
        if(orderConfirmationInfo){
            pushDataLayer({event: 'view', label: 'viewOrderConfirmation'})
        }
    }, [orderConfirmationInfo])

    if(!orderConfirmationInfo){
        return null
    }
    const { shipAddress, billAddress, email, isSubscribeMailmagazine, password, creditCard, userIdToken, ...itemInfo  } = orderConfirmationInfo

    const onClickCheckout = async () => {
        pushDataLayer({
            event: 'click',
            label: 'clickCheckoutButton'
        })
        try {
            const idToken = userIdToken || await createUserWithEmailAndPassword(firebaseAuth, email, password)
                .then(user => user.user.getIdToken())
                .catch(() => {throw { code: 'LoginErr', message: 'ユーザの作成に失敗しました。お手数ですが、最初からやり直しよろしくお願いします。'} })
            postCheckout.request({
                query: undefined,
                body: {
                    checkout: {
                        idToken,
                        cardToken: creditCard.token,
                        itemCode: itemInfo.itemCode,
                        couponCode: itemInfo.couponCode,
                        isSubscribeMailmagazine,
                        shipAddress,
                        billAddress
                    }
                }
            })
        }catch (e: any|ErrResult) {
            if(e?.code || e?.message){
                setErrResult(e)
            }
        }
    }

    return (
        <Modal close={close} isOpen maxWidth='900px' maxHeight='700px'>
            <p css={[pTagStyle, headTtl]}>ご注文内容の確認</p>
            <div css={containerStyle}>

                <ConfirmBlackTitle>お客様情報</ConfirmBlackTitle>
                <ul css={[ulTagStyle, listInfoStyle]}>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >お名前</p>
                        <p css={[pTagStyle, contentStyle]} >
                            {billAddress.lastName}
                            {' '}
                            {billAddress.firstName}
                        </p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>住所</p>
                        <p css={[pTagStyle, contentStyle]}>
                            {getPrefectureName(billAddress.prefectureId)}
                            {billAddress.address1}
                            {billAddress.address2}
                        </p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>電話番号</p>
                        <p css={[pTagStyle, contentStyle]}>{billAddress.phoneNumber}</p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>メールアドレス</p>
                        <div css={contentStyle}>
                            {email}
                            <br/>
                            {isSubscribeMailmagazine
                                // eslint-disable-next-line react/jsx-curly-brace-presence
                                ? (<> {'('}VitaNoteからのお知らせを受け取る{')'}</>)
                                // eslint-disable-next-line react/jsx-curly-brace-presence
                                : (<> {'('}VitaNoteからのお知らせを受け取らない{')'}</>)
                            }
                        </div>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>パスワード</p>
                        <p css={[pTagStyle, contentStyle]}>*********</p>
                    </li>
                </ul>
                <ConfirmBlackTitle>お届け先</ConfirmBlackTitle>
                <ul css={[ulTagStyle, listInfoStyle]}>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >お名前</p>
                        <p css={[pTagStyle, contentStyle]} >
                            {shipAddress.lastName}
                            {' '}
                            {shipAddress.firstName}</p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >住所</p>
                        <p css={[pTagStyle, contentStyle]} >
                            {getPrefectureName(shipAddress.prefectureId)}
                            {shipAddress.address1}
                            {shipAddress.address2}
                        </p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >電話番号</p>
                        <p css={[pTagStyle, contentStyle]}>{shipAddress.phoneNumber}</p>
                    </li>
                </ul>
                <ConfirmBlackTitle>クレジットカード情報</ConfirmBlackTitle>
                <ul css={[ulTagStyle, listInfoStyle]} >
                    <li>
                        <p css={[pTagStyle, titleStyle]} >カード番号</p>
                        <p css={[pTagStyle, contentStyle]} >************{creditCard.last4}</p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >有効期限</p>
                        <p css={[pTagStyle, contentStyle]}>{creditCard.exp_month}/{creditCard.exp_year}</p>
                    </li>
                </ul>
                <ConfirmBlackTitle>ご注文商品</ConfirmBlackTitle>
                <ul css={[ulTagStyle, listInfoStyle]}>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>商品</p>
                        <div css={[contentStyle, mobileColumStyle]}>
                            <img
                                css={productImgStyle}
                                src={itemInfo.image.url}
                                alt="product"
                            />
                            <p css={[pTagStyle, productTxtStyle]}>
                                {itemInfo.itemName}
                                <p css={[pTagStyle, productTxtStyle]}>
                                    <BreakLinePTag>{htmlDataset?.itemText}</BreakLinePTag>
                                </p>
                            </p>
                        </div>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>単価（税込）</p>
                        <p css={[pTagStyle, contentStyle]}>
                            <div>
                                {itemInfo.price}円
                                {htmlDataset.isAddRecurring && (' (初回支払い分)')}
                                <br/>
                                {htmlDataset.isAddRecurring && (
                                    <BreakLinePTag>※2回目以降については、下記にてご確認ください。</BreakLinePTag>
                                )}
                            </div>

                        </p>

                    </li>
                    {htmlDataset.isVisibleCoupon && itemInfo.couponDiscountPrice > 0 && (
                        <li>
                            <p css={[pTagStyle, titleStyle]}>クーポン割引額</p>
                            <p css={[pTagStyle, contentStyle]}>{itemInfo.couponDiscountPrice}円</p>
                        </li>
                    )}
                    <li>
                        <p css={[pTagStyle, titleStyle]} >送料</p>
                        <p css={[pTagStyle, contentStyle]}>{itemInfo.shippingFee}円</p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>合計</p>
                        <p css={[pTagStyle, contentStyle]}>
                            {itemInfo.price + itemInfo.shippingFee - itemInfo.couponDiscountPrice}円
                            {htmlDataset.isAddRecurring && (' (初回支払い分)')}
                            （獲得ポイント：{itemInfo.expectedPoints}pt）
                        </p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>発送予定日</p>
                        <p css={[pTagStyle, contentStyle]}>
                            <div>
                                {dateUtils.toJapaneseFormat(itemInfo.shipOnDate)}
                                <BreakLinePTag>{htmlDataset?.shipOnDateText}</BreakLinePTag>
                            </div>

                        </p>
                    </li>
                </ul>
                <ConfirmBlackTitle>
                    ご注文完了前にご確認ください
                </ConfirmBlackTitle>
                <BreakLinePTag>
                    {/* 過去の互換性を気にして、itemInfo?.legalDescriptionを表示させている */}
                    {htmlDataset?.confirmBeforePurchaseText || itemInfo?.legalDescription}
                </BreakLinePTag>
                <div>
                    <HoverButton label="注文を確定する" onClick={onClickCheckout} loading={postCheckout.loading} isDoubleSendPrevention />
                    <HoverButton label="入力内容を修正する" styleMode="gray" onClick={close} />
                </div>
            </div>
        </Modal>
    )
}
