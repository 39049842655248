
const dayOfWeekStr = [ '日', '月', '火', '水', '木', '金', '土' ]
export const dateUtils = {
    // 2022-03-18 → 2022年3月18日 (月)
    toJapaneseFormat: (dateSeparatedHyphen: string) => {
        const date = new Date(dateSeparatedHyphen)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const week = dayOfWeekStr[date.getDay()]
        return `${year}年${month}月${day}日 (${week})`
    }

}
