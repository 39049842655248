import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, {useMemo} from 'react'
import useSWR from 'swr'
import {StripePublicKey, swrParamsGetStripePublicKey} from '@/network/api/stripePublicKey'
import {ErrResult} from '@/network/fetcher'


export const CreditCardFormContainer: React.VFC<{
    children: React.ReactNode
}> = (props) => {
    const { children } = props
    const stripePublicKey = useSWR<StripePublicKey, ErrResult>(...swrParamsGetStripePublicKey(undefined))
    const stripePromise = useMemo(
        () => stripePublicKey.data ? loadStripe(stripePublicKey.data?.publicKey) : null,
        [stripePublicKey.data]
    )

    return (
        <Elements stripe={stripePromise}>
            {children}
        </Elements>
    )
}
