import React, {ChangeEvent, useEffect, useState} from 'react'
import {css} from '@emotion/react'
import {FormLabel} from '@components/atoms/FormLabel'
import {FieldMessage} from '@components/atoms/FieldMessage'
import {
    Control,
    Controller,
    FieldErrors,
    FieldPath,
    FieldPathValue,
    UnpackNestedValue,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue
} from 'react-hook-form'
import {NumberField} from '@components/atoms/NumberField'
import {Select, SelectOptionValue} from '@components/atoms/Select/Select'
import {containNumberRegex} from '@/utils/validator/containNumberValidator'
import {prefectures} from '@/configure/prefectures'
import {spaces} from '@/styles/spaces'
import {useGetZipCodeInfo} from '@/network/api/zipCodeInfo'
import {bodyFormStyle, inputTagStyle} from '@/styles/global'
import {usePushDataLayerOnce} from '@/utils/analytics'

const textFieldStyle = css({
    width: '100%',
    height: '56px',
    background: '#FFFFFF',
    border: '1px solid #EAEBED',
    boxSizing: 'border-box',
    padding: '12px',
    fontSize:'16px',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
        height: '45px',
    },
})


const prefectureOptions: SelectOptionValue[] = prefectures.map(prefecture => ({
    name: prefecture.name,
    value: `${prefecture.id}`
}))

export const AddressForm = <TFieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(props: {
    register: UseFormRegister<TFieldValues>,
    errors: FieldErrors,
    setValue:UseFormSetValue<TFieldValues>
    setError:UseFormSetError<TFieldValues>
    control:Control<TFieldValues, unknown>
    nameFirstName:TFieldName
    nameLastName:TFieldName
    nameZipCode:TFieldName
    namePrefectureId:TFieldName
    nameAddress1:TFieldName
    nameAddress2:TFieldName
    namePhoneNumber:TFieldName

}) => {
    const { register, setValue, setError, errors: parentErrors, control, nameFirstName, nameLastName, nameZipCode, namePrefectureId, nameAddress1, nameAddress2, namePhoneNumber } = props
    const [address2Msg,setAddress2Msg ] = useState<string | undefined>()
    type SetValueType = UnpackNestedValue<FieldPathValue<TFieldValues, TFieldName>>
    const baseFormName = nameFirstName.split('.')[0]
    const errors = parentErrors[baseFormName]
    const pushDataLayerOnce = usePushDataLayerOnce()
    const zipCodeInfo = useGetZipCodeInfo()

    useEffect(() => {
        if(zipCodeInfo.loading) return
        if(zipCodeInfo.data){
            const info = zipCodeInfo.data[0]
            setValue(namePrefectureId, info.prefectureId.toString() as SetValueType, { shouldValidate: true })
            setValue(nameAddress1, info.city + info.town as SetValueType)
            setError(nameZipCode , {})
            setError(nameAddress1, {})
        }else if(zipCodeInfo.error){
            setError(nameZipCode , {type: 'onChange', message: zipCodeInfo.error.message})
        }
        // リクエストの完了時にのみに反応すれば問題ない
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCodeInfo.loading])

    return (
        <>
            <li>
                <FormLabel isRequired>お名前</FormLabel>
                <div css={bodyFormStyle}>
                    <div
                        css={css({
                            display: 'flex',
                            flexDirection: 'row',
                        })}
                    >
                        <input key="lastName"  placeholder="姓" type='text' css={[inputTagStyle, textFieldStyle]} {...register(nameLastName, {
                            required: true,
                            onChange: () => {
                                pushDataLayerOnce({
                                    event: 'input',
                                    label: 'inputLastName'
                                })
                            }
                        })} />
                        <span css={css({padding: spaces.size.space10,})}/>
                        <input key="firstName" placeholder="名" type='text' css={[inputTagStyle, textFieldStyle]} {...register(nameFirstName, {
                            required: true,
                            onChange: () => {
                                pushDataLayerOnce({
                                    event: 'input',
                                    label: 'inputFirstName'
                                })
                            }
                        })} />
                    </div>
                    {(errors?.firstName || errors?.lastName) && (<FieldMessage isErr>入力してください。</FieldMessage>)}
                </div>
            </li>

            <li>
                <FormLabel isRequired>郵便番号</FormLabel>
                <div css={[bodyFormStyle, css({maxWidth:'300px'})]}>
                    <Controller
                        rules={{
                            required: '入力してください。',
                            minLength: { value: 7, message: '有効な郵便番号を入力してください。'},
                            validate: { validZipCode:() => zipCodeInfo.error?.message || true },
                            onChange: (event: ChangeEvent<HTMLInputElement>) => {
                                pushDataLayerOnce({
                                    event: 'input',
                                    label: 'inputPostcode'
                                })
                                const inputValue = event.target.value
                                if(inputValue.length === 7){
                                    zipCodeInfo.request({body: undefined, query: {zipCode: event.target.value}})
                                }
                            }
                        }}
                        name={nameZipCode}
                        control={control}
                        render={({ field })=><NumberField placeholder='1234567' maxLength={7} {...field} />}
                    />
                    {(errors?.zipcode) && (<FieldMessage isErr>{errors.zipcode.message}</FieldMessage>)}
                </div>
            </li>

            <li>
                <FormLabel isRequired>住所</FormLabel>
                <div css={bodyFormStyle}>
                    <FormLabel isSub>都道府県</FormLabel>
                    <div css={css({maxWidth:'300px'})}>
                        <Select<TFieldValues> register={register} name={namePrefectureId} options={prefectureOptions}/>
                        {(errors?.prefectureId) && (<FieldMessage isErr>{errors?.prefectureId.message}</FieldMessage>)}
                    </div>

                    <FormLabel isSub>市区町村</FormLabel>
                    <input
                        placeholder="市区町村"
                        type='text' css={[inputTagStyle, textFieldStyle]}
                        {...register(nameAddress1, {required: '入力してください。'})} />
                    {(errors?.address1) && (<FieldMessage isErr>{errors.address1.message}</FieldMessage>)}

                    <FormLabel isSub>
                        番地・建物名・部屋番号
                    </FormLabel>
                    <input
                        placeholder="番地・建物名・部屋番号"
                        type='text' css={[inputTagStyle, textFieldStyle]}
                        {...register(nameAddress2, {
                            required: '入力してください。',
                            onChange: () => {
                                pushDataLayerOnce({
                                    event: 'input',
                                    label: 'inputAddress'
                                })
                            },
                            onBlur: (event: {target: {value: string}}) => {
                                const address2 = event.target.value
                                if(address2.match(containNumberRegex) === null){
                                    setAddress2Msg('住所に数字の記載がありません。ご確認ください。')
                                }else{
                                    setAddress2Msg(undefined)
                                }
                            }
                        })} />
                    {(errors?.address2) && (<FieldMessage isErr>{errors.address2.message}</FieldMessage>)}
                    {address2Msg && <FieldMessage isErr={false}>{address2Msg}</FieldMessage>}
                </div>
            </li>
            <li>
                <FormLabel isRequired>電話番号</FormLabel>
                <div css={[bodyFormStyle, css({maxWidth: '400px'})]}>
                    <Controller
                        name={namePhoneNumber}
                        control={control}
                        rules={{
                            required: '入力してください。',
                            onChange: () => {
                                pushDataLayerOnce({
                                    event: 'input',
                                    label: 'inputPhoneNumber'
                                })
                            },
                        }}
                        render={({ field })=>
                            <NumberField placeholder='09012345678' {...field}/>
                        }
                    />
                    {(errors?.phoneNumber) && (<FieldMessage isErr>{errors.phoneNumber.message}</FieldMessage>)}
                </div>
            </li>


        </>
    )
}
