import React from 'react'
import { css } from '@emotion/react'
import { groupCouponBase64 }  from '@assets/img/groupCouponBase64'

/* プロモーションのリンク先URL */
const link = 'https://lin.ee/VdYLyme'

const overlayStyle = css({
    position: 'fixed',
    top: '-100vh',
    right: '-100vw',
    bottom: '-100vh',
    left: '-100vw',
    zIndex: '300',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
})

const containerStyle = css({
    position: 'relative',
})

const promotionImageStyle = css({
    width: '300px',
    height: 'auto',
    margin: '0 auto',
    display: 'block',
})

const closeButtonStyle = css({
    position: 'absolute',
    top: '-15px',
    right: '-15px',
    zIndex: '301',
    width: '30px',
    height: '30px',
    padding: 'auto',
    cursor: 'pointer',
    border: '1px solid #000',
    borderRadius: '50%',
})
/**
 * 注文完了画面で表示するキャンペーン用のモーダル
 * @description
 *   - 表示要件
 *     - キャンペーン用画像が表示されていること
 *     - クーポン獲得ボタンが表示されていること
 *   - 非表示要件
 *     - 指定秒数後に表示される
 *     - クーポン獲得ボタンを押下するとLINEの友達登録画面に遷移すること
 * @package
 */
export const PromotionModal: React.FC<{
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    isOpen: boolean
}> = ({
    isOpen, setIsOpen
}) => {
    const closeModal = () => setIsOpen(false)

    if (!isOpen) return null

    return (
        <div css={overlayStyle}>
            <div css={containerStyle}>
                <button
                    type="button"
                    onClick={closeModal}
                    css={closeButtonStyle}
                >
                    ✕
                </button>
                <a href={link} target="_blank" rel="noreferrer">
                    <img src={groupCouponBase64} alt="LINEクーポン" css={promotionImageStyle} />
                </a>
            </div>
        </div>
    )
}
