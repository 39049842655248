import {useCallback, useState} from 'react'
import {fetchSignInMethodsForEmail, signInWithEmailAndPassword} from 'firebase/auth'
import {isValidEmail} from '@/utils/validator/emailValidator'
import {firebaseAuth} from '@/network/firebase/firebaseAuth'

export type EmailAuthMethodStatus = 'newUser' | 'authByPassword' | 'authBySns' | 'invalidEmail' | undefined
export type EmailLoginStatus = 'newUser' | 'loginSuccess' |  'loginFailure'

/**
 * FirebaseAuthenticationに、メールアドレスの状態を確認するhooks
 */
export const useFirebaseAuthEmailStatus = () => {

    const [authMethodStatus, setAuthMethodStatus] = useState<EmailAuthMethodStatus>()
    /**
     * 既存ユーザでログインが成功したときのみ存在する
     */
    const [userIdToken, setUserIdToken] = useState<string | undefined>()
    const checkEmailAuthStatus = useCallback(async (email?: string): Promise<EmailAuthMethodStatus> => {
        if( !email || !isValidEmail(email) ){
            setAuthMethodStatus('invalidEmail')
            return Promise.resolve('invalidEmail')
        }
        try {
            const authMethods = await fetchSignInMethodsForEmail( firebaseAuth, email)
            if(authMethods.length > 0){
                if(authMethods.includes('password')){
                    setAuthMethodStatus('authByPassword')
                    return 'authByPassword'
                }
                setAuthMethodStatus('authBySns')
                return 'authBySns'

            }
            setAuthMethodStatus('newUser')
            return 'newUser'

        }catch (e) {
            return 'invalidEmail'
        }

    },[setAuthMethodStatus])

    /**
     * 新規ユーザならnewUser
     * 認証済みのユーザなら、 パスワード認証できるかチェックしてログインできるか
     * 認証済みのユーザなら、パスワード認証できなければ
     */
    const checkLoginBeforeOrderConfirm = useCallback(async (email: string, password:string): Promise<EmailLoginStatus> => {
        // authMethodStatusがundefinedのときは、checkEmailAuthStatusが一度も実行されていない状態なので、少なくとも一度は実行する
        const currentAuthMethodStatus = authMethodStatus || await checkEmailAuthStatus(email)

        if(currentAuthMethodStatus === 'newUser'){
            setUserIdToken(undefined)
            return 'newUser'
        }

        if(currentAuthMethodStatus === 'authByPassword' || currentAuthMethodStatus === 'authBySns'){
            try {
                const result = await signInWithEmailAndPassword(firebaseAuth, email, password)
                const idToken = await result.user.getIdToken()
                setUserIdToken(idToken)
                return 'loginSuccess'
            }catch (e) {
                setUserIdToken(undefined)
                return 'loginFailure'
            }
        }
        setUserIdToken(undefined)
        return 'loginFailure'
    },[authMethodStatus, checkEmailAuthStatus])


    return {
        authMethodStatus,
        checkEmailAuthStatus,
        checkLoginBeforeOrderConfirm,
        /**
         * 既存ユーザでログインが成功したときのみ存在する
         */
        userIdToken
    }
}
