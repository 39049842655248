import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { PurchaseLayout } from '@components/organisms/PurchaseLayout'
import { ReactComponent as ImgThanks } from '@assets/img/img_thanks.svg'
import { MobileAppDownload } from '@components/organisms/MobileAppDownload'
import { pTagStyle } from '@/styles/global'
import { pushDataLayer } from '@/utils/analytics'
import { PromotionModal } from '../atoms/PromotionModal'

const thanksStyle = css({
    fontSize: '22px',
    fontWeight: '600',
    textAlign: 'center',
    color: '#2eb6aa',
    letterSpacing: '0.1em',
    margin: '50px 0 30px',
    '@media (max-width: 480px)': {
        fontSize: '18px',
    },
})

const descriptionStyle = css({
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    margin: '40px 0 0',
    '@media (max-width: 480px)': {
        fontSize: '14px',
        textAlign: 'left',
        margin: '30px 0 0',
    },
})

const descriptionBrStyle = css({
    '@media (max-width: 480px)': {
        display: 'none',
    },
})

export const PurchaseThanksPage: React.VFC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        pushDataLayer({ event: 'view', label: 'viewCheckout' })

        const timer = setTimeout(() => {
            setIsOpen(true)
            // レンダリングで時間がかかるので、その分 遅延させる
        }, 1000 + 500)
        return () => clearTimeout(timer)
    }, [])

    return (
        <PurchaseLayout>
            {/* 注文完了 ====================== */}
            <section>
                <PromotionModal isOpen={isOpen} setIsOpen={setIsOpen} />
                <p css={[pTagStyle, thanksStyle]}>
                    ご注文ありがとうございました。
                </p>
                <ImgThanks
                    css={css({
                        margin: '0 auto',
                        display: 'block',
                    })}
                />
                <p css={[pTagStyle, descriptionStyle]}>
                    栄養検査にはVITANOTEアプリが必要です。
                    <br css={descriptionBrStyle} />
                    ご注文時に入力したメールアドレス・パスワードでアプリにログインしてください。
                </p>
                <div css={css({ margin: '40px 0 0' })}>
                    {/* ローカルの開発環境だと表示されないので注意 */}
                    <iframe
                        title="google form"
                        src="https://docs.google.com/forms/d/e/1FAIpQLSeeSykaO1916CO_oE0VJ95wZk2bR0pq9JymgT2zpfJ-5K2Ghg/viewform?embedded=true"
                        width="100%"
                        height="1500"
                        frameBorder="0"
                    />
                </div>
                <MobileAppDownload />
            </section>
        </PurchaseLayout>
    )
}
