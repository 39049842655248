import {ApiInfo, apiInfoFunc} from '../apiInfo'

/** 同一SKUの他の商品 １つのSKUで複数商品があるので必要。例えば別の味の商品など */
export type ItemVariant = {
    name: string
    code: string
}

export type Item = {
    /** 商品名 */
    name: string
    /** 価格 */
    price: number
    /** 商品数 */
    quantity: number
    /** 商品コード */
    code: string
    /** 画像 */
    image: {
        /** 画像URL */
        url: string
    }
    legalDescription: string
    /** 同一SKUの他の商品。なければ配列は空になる */
    variants: ItemVariant[]
}


// APIのURLに対して、型を確定させる
const apiInfoGetItem: ApiInfo<Item, {itemCode: string}> = {
    method: 'GET',
    url: (query) => `/web_ec_v2/items/${query.itemCode}`,
    response: {} as Item,
    query: {} as {itemCode: string},
    requestBody: undefined
}

export const swrParamsGetItem = apiInfoFunc.toSwrParamsCreator(apiInfoGetItem)
