import React from 'react'
import {css} from '@emotion/react'
import {ReactComponent as AppIcon} from '@assets/img/img_app.svg'
import {ReactComponent as AppleStore} from '@assets/img/img_download_ios.svg'
import {ReactComponent as AndroidStore} from '@assets/img/img_download_android.svg'
import {pTagStyle, ulTagStyle} from '@/styles/global'


const downloadContainerStyle = css({
    'background': '#fff',
    'padding': '20px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'margin': '100px 0 0',
    '@media (max-width: 480px)':{
        margin: '60px 0 0'
    }
})

const downloadHeaderStyle = css({
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 10px'
})

const downloadHeaderTitleStyle = css({
    fontSize: '13px',
    fontWeight: '600',
    marginLeft: '16px',
})
export const MobileAppDownload: React.VFC = () => (
    <div css={downloadContainerStyle}>
        <div css={downloadHeaderStyle}>
            <AppIcon width="54px" height='54px' css={css({
                border: '1px solid #EAEBED',
                borderRadius: 10,
            })}  />
            <p css={[downloadHeaderTitleStyle]}>
                VITANOTEアプリの
                <br />
                無料ダウンロードはコチラ
            </p>

        </div>
        <ul css={[ulTagStyle, css({
            display: 'flex',
            justifyContent: 'center',
            margin: '0'
        })]}>
            <li>
                <a
                    href='https://rd.vitanote.jp/?download_app=ios&amp;utm_source=servicesite&amp;utm_medium=link_2&amp;_ga=2.144272565.1467937599.1611280830-1997901344.1605328214'
                    target='_blank'
                    rel='noreferrer'>
                    <AppleStore width={160} height={50}/>

                </a>
            </li>
            <li>
                <a
                    href='https://rd.vitanote.jp/?download_app=android&amp;utm_source=servicesite&amp;utm_medium=link_2&amp;_ga=2.144272565.1467937599.1611280830-1997901344.1605328214'
                    target='_blank'
                    rel='noreferrer'>
                    <AndroidStore width={160} height={50}/>
                </a>
            </li>
        </ul>
        <div css={[css({marginTop:'10px'})]}>
            <p css={[pTagStyle, css({fontSize:'12px'})]}>
                ご注文時に入力したメールアドレスとパスワードでログインしてください。
            </p>
        </div>
    </div>
)
