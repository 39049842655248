import {css} from '@emotion/react'


export const globalStyle = css({
    body: {
        margin: 0,
        fontFamily: '\'Hiragino Kaku Gothic Pro\', sans-serif',
        position: 'relative'
    },
})

export const ulTagStyle = css({
    padding: 0,
    margin: 0,
    listStyle: 'none'
})

export const pTagStyle = css({
    padding: 0,
    margin: 0
})

export const selectTagStyle = css({
    'border': 'none',
    'outline': 'none',
    'WebkitAppearance': 'none',
    'MozAppearance': 'none',
    'appearance': 'none',
    'color': '#2C2C2C',
    '-ms-expand': {
        'display': 'none',
        'WebkitAppearance': 'none',
        'MozAppearance': 'none',
        'appearance': 'none'
    },
    '::placeholder': {
        color: '#bbb'
    }
})
// 二番目以降のセクションタグ
export const sectionTagAfterStyle = css({
    margin: '70px 0 0',
    '@media  (max-width: 480px)': {
        margin: '50px 0 0',
    }
})


export const inputTagStyle = css({
    'border': 'none',
    'outline': 'none',
    'WebkitAppearance': 'none',
    'MozAppearance': 'none',
    'appearance': 'none',
    '::placeholder': {
        color: '#bbb'
    }
})

export const buttonTagStyle = css({
    'border': 'none',
    'outline': 'none',
    'WebkitAppearance': 'none',
    'MozAppearance': 'none',
    'appearance': 'none'
})

export const bodyFormStyle = css({
    flex: '1 1 auto',
    minWidth: '10px'
})
