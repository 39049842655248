import React, {useRef} from 'react'
import {css} from '@emotion/react'
import {ControllerRenderProps} from 'react-hook-form'

import {Checkbox} from '@components/atoms/CheckBox/Checkbox'
import {createUuid} from '@/utils/uuid'
import {inputTagStyle} from '@/styles/global'


const textStyles = css({
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#444',
    userSelect: 'none',
    cursor: 'pointer',
})
const labelStyle = css({
    display: 'flex',
    alignItems: 'center',
})

/**
 * テキスト付き Checkbox
 * @param props
 * @constructor
 */
export const CheckboxText: React.VFC<{
    label: string
} & ControllerRenderProps> = (props) => {
    const { label, ...restProps } = props
    // labelとinputで共有することで、チェックボックス以外をクリックしてもチェックが入る
    const checkBoxUuid = useRef(createUuid())

    return (
        <label htmlFor={checkBoxUuid.current} css={labelStyle}>
            <input type="checkbox" {...restProps} css={[inputTagStyle]} id={checkBoxUuid.current} checked={restProps.value} />
            <Checkbox checked={restProps.value}/>
            <span css={textStyles}>{label}</span>
        </label>
    )
}
