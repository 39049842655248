import {apiInfoFunc, ApiInfo} from '@/network/apiInfo'


export type StripePublicKey = {
    publicKey: string
}

/**
 * Stripeに登録済みのクレジットカードの情報
 */
export type CreatedCreditCard = {
    token: string
    exp_month:number
    exp_year:number
    last4:string
}



const apiInfoGetStripePublicKey: ApiInfo<StripePublicKey> = {
    method: 'GET',
    url: () => '/web_ec_v2/credentials/payment',
    response: {} as StripePublicKey,
    query: undefined,
    requestBody: undefined
}

export const swrParamsGetStripePublicKey = apiInfoFunc.toSwrParamsCreator<
    typeof apiInfoGetStripePublicKey.response,
    typeof apiInfoGetStripePublicKey.query,
    typeof apiInfoGetStripePublicKey.requestBody
>(apiInfoGetStripePublicKey)

