// 参考: vitanote-ec-frontendを参考にして修正
export const validEmailRegex = /^[a-z0-9.+?_/-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/
export const isValidEmail = (email: string): boolean => {
    const isValidFormat = validEmailRegex.test(email)
    const isValidDot = email.indexOf('..') === -1
    const isValidAtSign = email.indexOf('.@') === -1 && (email.indexOf('@.') === -1)
    const isValidPrefixSize = email.split('@')[0].length < 64
    const isValidSize = email.length < 256

    return isValidFormat && isValidDot && isValidAtSign && isValidPrefixSize && isValidSize
}
