import { useState} from 'react'
// Stripe.js
// https://stripe.com/docs/stripe-js/react
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import {CreatedCreditCard} from '@/network/api/stripePublicKey'

type CreditCardHook = {
    /* 失敗するとundefinedが返される。 */
    createCreditCard: () => Promise<CreatedCreditCard|undefined>
    /* 失敗すると値が入る。 */
    stripeError?:StripeError
    /* 前回作成されたクレジットカード返す。前回失敗しているとundefinedが返される */
    creditCard?:CreatedCreditCard
}
/**
 * クレジットカードを作成する
 * 注意: Elementsの内部でないと使えないため、<CreditCardFormContainer>でラップされたコンポーネント内で使う必要がある
 */
export const useCreateCreditCard = (): CreditCardHook => {

    const stripe = useStripe()
    const elements = useElements()
    const [error, setError] = useState<StripeError | undefined>()
    const [createdCreditCard, setCreatedCreditCard] = useState<CreatedCreditCard | undefined>()
    /**
     * クレジットカードを作成する
     * 作成に成功したらクレジットカードの情報を返す
     * 失敗したらundefinedを返して、エラーメッセージをセットする
     */
    const createCreditCard = async (): Promise<CreatedCreditCard|undefined>  => {
        if (!stripe || !elements || !elements.getElement(CardElement)) {
            setError({
                type: 'authentication_error',
                message: 'Formが有効ではありません'
            })
            setCreatedCreditCard(undefined)
            return undefined
        }
        const cardElement = elements.getElement(CardElement)
        const results = await stripe.createToken(cardElement!)
        if (results.token && results.token.card) {
            setError(undefined)
            const creditCard = {
                token: results.token.id,
                exp_month: results.token.card.exp_month,
                exp_year: results.token.card.exp_year,
                last4: results.token.card.last4
            }
            setCreatedCreditCard(creditCard)
            return creditCard
        }
        setError(results.error)
        setCreatedCreditCard(undefined)
        return undefined
    }

    return {
        createCreditCard,
        stripeError: error,
        creditCard: createdCreditCard
    }
}
