import React from 'react'
import {css} from '@emotion/react'

export const Space: React.VFC<{
    height: string
}> = (props) => {
    const { height } = props

    return (
        <div css={css({marginBottom: height})}/>
    )
}
