import {MouseEventHandler, useState} from 'react'

// 2重送信を防止する関数を返す
export const useDoubleSendPrevention = (
    isDoubleSendPrevention: boolean,
    onClick?: MouseEventHandler<HTMLInputElement>,
): {
    onClickIfNotProcessing: MouseEventHandler<HTMLInputElement>|undefined,
    processing: boolean
} => {
    const [processing, setProcessing] = useState<boolean>(false)

    return {
        processing,
        onClickIfNotProcessing: !isDoubleSendPrevention
            ? onClick
            : (event) => {
                if (processing) return
                setProcessing(true)
                if(onClick) onClick(event)
                setTimeout(() => {
                    setProcessing(false)
                }, 3000)
            }
    }

}
