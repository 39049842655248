import {ApiInfo, apiInfoFunc} from '../apiInfo'

type ZipCodeInfo =  {
    prefecture: string,
    city: string,
    town: string,
    prefectureId: number
}

// APIのURLに対して、型を確定させる
const apiInfoGetZipCodeInfo: ApiInfo<ZipCodeInfo[], {zipCode: string}, undefined > = {
    method: 'GET',
    url: (query) => `/web_ec_v2/addresses/${query.zipCode}`,
    response: {} as ZipCodeInfo[],
    query: {} as {zipCode: string},
    requestBody:undefined
}

export const swrParamsZipCodeInfo = apiInfoFunc.toSwrParamsCreator(apiInfoGetZipCodeInfo)
export const useGetZipCodeInfo = apiInfoFunc.toUseRequest(apiInfoGetZipCodeInfo)
