import React from 'react'
import { css } from '@emotion/react'
import { fonts } from '../../styles/fonts'

const textStyle = css({
    fontSize: fonts.size.fontMobile12Pc16,
    fontWeight: 'bold',
})
const subTextStyle = css({
    fontSize: fonts.size.font12,
    color: '#666',
})
const requireMarkStyle = css({
    fontSize: fonts.size.fontMobile12Pc16,
    fontWeight: 'bold',
    color: '#DC3251',
    marginLeft: '3px',
})
const labelStyle = css({
    flex: '0 0 180px',
    padding: '16px 0',
    '@media (max-width: 768px)': {
        padding: '0 0 1px',
    },
})
const subLabelStyle = css({
    padding: '8px 0 2px',
    '@media (max-width: 768px)': {
        padding: '4px 0 0',
    },
})

export const FormLabel: React.VFC<{
    isRequired?: boolean
    isSub?: boolean
    children: React.ReactNode
}> = (props) => {
    const { isRequired, children, isSub } = props

    return (
        <div css={isSub ? subLabelStyle : labelStyle}>
            <span css={isSub ? subTextStyle : textStyle}>{children}</span>
            {isRequired && <span css={requireMarkStyle}>*</span>}
        </div>
    )
}
