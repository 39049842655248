import React from 'react'
import { css } from '@emotion/react'
import { colors } from '../../styles/colors'
import { fonts } from '../../styles/fonts'
import { ReactComponent as TtlPurchase } from '../../assets/img/img_purchase.svg'

const containerStyles = css({
    backgroundColor: colors.background,
    maxWidth: '100vw',
    minHeight: '100vh',
    padding: '60px',
    paddingRight: '40px',
    paddingLeft: '40px',
    color: colors.text,
    '@media (max-width: 639px)': {
        padding: '50px',
        paddingRight: '20px',
        paddingLeft: '20px',
    },
})

// ご注文フォームの背景
// 画面の幅はココで吸収したい気持ち
export const PurchaseLayout: React.VFC<{
    children: React.ReactNode
}> = (props) => {
    const { children } = props

    return (
        <div css={containerStyles}>
            <div css={css({ maxWidth: '860px', margin: '0 auto' })}>
                <div
                    css={css({
                        textAlign: 'center',
                        marginBottom: 50,
                        '@media (max-width: 768px)': {
                            marginBottom: 20,
                        },
                    })}
                >
                    <TtlPurchase
                        css={css({
                            margin: '0 auto 20px',
                            display: 'block',
                            width: '180px',
                            '@media (max-width: 768px)': {
                                width: '110px',
                                margin: '0 auto 10px',
                            },
                        })}
                    />
                    <div
                        css={css({
                            fontSize: fonts.size.fontMobile24Pc36,
                            fontWeight: '600',
                            letterSpacing: '.1em',
                        })}
                    >
                        【ご注文フォーム】{' '}
                    </div>
                </div>
                {children}
            </div>
        </div>
    )
}
