import React from 'react'
import { css } from '@emotion/react'
import { fonts } from '../../styles/fonts'

const containerStyle = css({
    backgroundColor: '#353535',
    padding: '6px 30px',
    marginBottom: '16px',
    '@media (max-width: 480px)': {
        marginBottom: '8px',
        padding: '4px 10px',
    },
})

const titleStyle = css({
    flex: 1,
    color: 'white',
    fontSize: fonts.size.fontMobile12Pc16,
    fontWeight: '500',
})

export const ConfirmBlackTitle: React.VFC<{
    children: React.ReactNode
}> = (props) => {
    const { children } = props

    return (
        <div css={containerStyle}>
            <div css={titleStyle}>{children}</div>
        </div>
    )
}
