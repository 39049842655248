import { toSize } from './utils'
/**
 * figmaのfontsizeをベースにしてフォントサイズを決定する
 * PC画面やモバイルへのフォントサイズの違いはココで吸収する
 */
export const fonts = {
    size: {
        fontMobile32Pc24: toSize([32, 24]),
        fontMobile24Pc36: toSize([24, 36]),
        fontMobile18Pc22: toSize([18, 22]),
        fontMobile14Pc18: toSize([14, 18]),
        fontMobile14Pc16: toSize([14, 16]),
        fontMobile12Pc16: toSize([12, 16]),
        fontMobile12Pc14: toSize([12, 14]),
        fontMobile12Pc13: toSize([12, 13]),
        font12: toSize([12]),
    },
}
