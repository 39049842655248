import {UserAddress} from '@/network/api/orderConfirmation'
import {ApiInfo, apiInfoFunc} from '@/network/apiInfo'
import {NoContentResult} from '@/network/fetcher'


export type Checkout = {
    checkout: {
        /**
         * ユーザのIDトークン
         */
        idToken:string
        cardToken:string
        itemCode:string
        couponCode:string
        isSubscribeMailmagazine:boolean
        shipAddress:UserAddress
        billAddress:UserAddress
    }
}

const apiInfoPostCheckout: ApiInfo<NoContentResult, undefined, Checkout> = {
    method: 'POST',
    url: () => '/web_ec_v2/checkout',
    response: {} as NoContentResult,
    query: undefined,
    requestBody: {} as Checkout
}

export const usePostCheckout = apiInfoFunc.toUseRequest(apiInfoPostCheckout)
