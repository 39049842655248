import React, {useEffect, useState} from 'react'
import useSWR from 'swr'
import {PurchaseFormPage} from '@components/pages/PurchaseFormPage/PurchaseFormPage'
import {PurchaseThanksPage} from '@components/pages/PurchaseThanksPage'
import {css, Global} from '@emotion/react'
import {ErrorModal} from '@components/organisms/ErrorModal'
import {Item, swrParamsGetItem} from './network/api/item'
import {ErrResult} from './network/fetcher'
import {isStaging} from '@/configure/env'
import {globalStyle} from '@/styles/global'
import {useHtmlDataset} from '@/configure/htmlDataset'
import { CreditCardFormContainer } from './components/atoms/CreditCardForm/CreditCardFormContainer'



const App:React.VFC = () => {

    const [page, setPage] = useState<Page>('form')
    const [errResult, setErrResult] = useState<ErrResult|undefined>(undefined)
    const {itemCode, ...customConfirmationHtmlDataset} = useHtmlDataset()


    const item = useSWR<Item, ErrResult>(...swrParamsGetItem({itemCode}))

    useEffect(() => {
        if(item.error){
            setErrResult({ code: item.error.code, message: '現在販売しておりません。' })
        }
    }, [item.data, item.error])

    return (
        <div>
            <Global styles={globalStyle} />
            {errResult && <ErrorModal errResult={errResult} setErrResult={setErrResult}/> }
            {isStaging && <div css={css({textAlign: 'center', background: '#ffc53d', color:'white'})}>ステージング環境</div>}
            {page === 'form' && (
                <CreditCardFormContainer>
                    <PurchaseFormPage item={item.data}  setPage={setPage} setErrResult={setErrResult} htmlDataset={customConfirmationHtmlDataset}/>
                </CreditCardFormContainer>
            )}
            {page === 'thanks' && (
                <PurchaseThanksPage/>
            )}
        </div>
    )
}

export default App
