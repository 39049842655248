import React from 'react'
import { css } from '@emotion/react'
import Modal from '@components/atoms/Modal'
import { ErrResult } from '@/network/fetcher'

const containerStyle = css({
    fontSize: '13px',
    width: '300px',
    // height: '200px'
})

export const ErrorModal: React.VFC<{
    errResult: ErrResult
    setErrResult: React.Dispatch<React.SetStateAction<ErrResult | undefined>>
}> = (props) => {
    const { errResult, setErrResult } = props
    return (
        <Modal
            close={() => {
                setErrResult(undefined)
            }}
            isOpen
            maxWidth="300px"
            maxHeight="400px"
        >
            {/* <p css={titleStyle}>エラー</p> */}
            <div css={containerStyle}>
                申し訳ございません。
                <br />
                {errResult.message}
            </div>
        </Modal>
    )
}
