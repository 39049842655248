import React from 'react'
import {css} from '@emotion/react'
import {fonts} from '@/styles/fonts'

/**
 * \nで改行されれる p tag
 * undefinedなら表示しない
 */
export const BreakLinePTag: React.VFC<{
    children?: string
}> = (props) => {
    const { children } = props
    if(!children) return null
    return (
        <p
            css={css({
                font: '500 13px/1.3em Hiragino Kaku Gothic Pro, sans-serif',
                fontSize: fonts.size.fontMobile12Pc13,
                whiteSpace:'pre-wrap',
                margin: '4px 0 0 0',
                lineHeight:'18px' // モバイルで詰まって見える
            })}
        >
            {children}
        </p>
    )
}
