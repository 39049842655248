import React from 'react'
import { css } from '@emotion/react'
import {useDoubleSendPrevention} from '@components/atoms/Button/useDoubleSendPrevention'
import {inputTagStyle} from '@/styles/global'

const buttonStyles = css({
    color: '#fff',
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'center',
    letterSpacing: '0.04em',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    padding: '0 24px',
    background: '#2eb6aa',
    border: '2px solid #2eb6aa',
    borderRadius: '4px',
    transition: '0.1s',
    cursor: 'pointer',
    maxWidth: '500px',
    width: '100%',
    margin: '50px auto 0',
    boxSizing: 'border-box',
    // boxShadow: '0 2px 4px rgb(0 0 0 / 20%)',
    ':hover': {
        color: '#2eb6aa',
        background: '#fff',
    },
    '@media (max-width: 480px)': {
        fontSize: '18px',
        height: '56px',
        margin: '30px auto 0',
        maxWidth: '280px',
    },
})

const grayButtonStyle = css({
    color: '#666',
    background: '#e3e3e3',
    border: '1px solid #e3e3e3',
    fontSize: '16px',
    maxWidth: '300px',
    height: '50px',
    margin: '20px auto 0',
    ':hover': {
        borderColor: '#ccc',
        color: '#666',
        background: '#e3e3e3',
    },
    '@media (max-width: 480px)': {
        fontSize: '14px',
        maxWidth: '220px',
        height: '40px',
        margin: '16px auto 0',
    },
})

const loadingStyle = css({
    opacity: 0.4,
    ':hover': {
        color: 'rgba(255,255,255)',
        background: 'rgba(46,182,170)',
        cursor: 'inherit',
    },
})

/**
 * ホバーするとデザインが変わるボタン
 * @param props
 * @constructor
 */
export const HoverButton: React.VFC<
    {
        label: string
        loading?: boolean
        styleMode?: 'normal' | 'gray'
        // onSubmitでは使えないので注意
        isDoubleSendPrevention?: boolean
    } & React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >
> = (props) => {
    const { label, loading, type = 'button', styleMode = 'normal', isDoubleSendPrevention, onClick, ...restProps } = props
    const { processing, onClickIfNotProcessing } = useDoubleSendPrevention(!!isDoubleSendPrevention, onClick)

    // propsで渡すとsubmitが、onClick:undefinedでたまにうまく行かなくなるため、めんど臭いことをしている
    const restPropsAddOnClick = onClick ? {
        ...restProps, onClick: onClickIfNotProcessing
    } : {
        ...restProps
    }
    
    return (
        <input
            css={[
                inputTagStyle,
                buttonStyles,
                styleMode === 'gray' ? grayButtonStyle : null,
                loading || processing ? loadingStyle : null,
            ]}
            {...restPropsAddOnClick}
            type={type}
            disabled={loading || processing}
            value={label}
        />
    )
}
